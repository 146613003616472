import React, { useState, useEffect, useContext, Fragment } from 'react';
import styled from 'styled-components';
import { path } from 'ramda';
import moment from 'moment';
import { RiCloseCircleLine } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5";
import { useParams } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import Rating from '@mui/material/Rating';
import { breakpoints } from '../../_helpers/breakpoints';
import { } from '../../_elements';

import MultipleChoice from './MultipleChoice';
import MultipleChoiceWithImage from './MultipleChoiceWithImage';
import { getAnswedData,
         answerSuccess } from '../../_helpers/commons';

import { ContentContext } from '../../_context';

import icon_evaluation_win from '../../_assets/images/icon_evaluation_win.svg';
import icon_evaluation_lose from '../../_assets/images/icon_evaluation_lose.svg';

import { GrLike, GrDislike } from "react-icons/gr";
import { BiLike, BiDislike } from "react-icons/bi";


import { STATUS_COURSE_BLOCKED,
         STATUS_COURSE_UNLOCKED,
         STATUS_COURSE_VIEWED,
         STATUS_COURSE_COMPLETED,
         STATUS_COURSE_REPROVED,
         STATUS_COURSE_PENDING_CONNECTION,
         STATUS_COURSE_IN_PROGRESS } from '~/_helpers/commons';

const EvaluationStyled = styled.div`
  /* top: 0;
  left: 0;
  position: fixed; */
  display: flex;
  flex-direction: column;
  //align-items: center;
  //width: 100%;
  //height: 100%;
  //justify-content: flex-start;
  z-index: 10;
  padding-top: 0;
  //background-color: rgba(0, 0, 0, 0.75);
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  

  .evaluation_inner{
    position: relative;
    width: 100%;
    min-height: ${props => props.isLoading ? "" : "360px"};
    border-radius: 0;
    background-color: ${props => props.win == null ? '#FFFFFF' : props.win.back };
    box-sizing: border-box;
    padding: 8vw 8vw 8vw 8vw;
    z-index: 12;
    
    .box_result_waiting{
      width: 100%;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .box_result{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .media{
        margin-top: 15px;
        margin-bottom: 15px;
        &.imgWin{
          width: 290px;
        }
        &.imgLose{
          width: 280px;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        img{
          float: left;
          width: 100%;
        }
      }
      .title{
        font-size: 30px;
        line-height: 30px;
        font-weight: 600;
        text-align: center;
        color: #FFFFFF;
      }
      .description{
        font-size: 20px;
        line-height: 25px;
        font-weight: 400;
        text-align: center;
        margin-top: 10px;
        color: #FFFFFF;
      }
      .points{
        font-size: 25px;
        line-height: 25px;
        font-weight: 600;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 15px;
        color: #FFC600;
      }
      .likes{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
        >.loader{
          svg{
            color: #faaf00;
          }
        }
        .title{
          font-size: 18px;
          line-height: 18px;
          font-weight: 500;
          color: #FFFFFF;//#FFC600;
        }
        .like_actions{
          display: flex;
          flex-direction: row;
          font-size: 35px;
          line-height: 35px;
          margin-top: 10px;
          color: #FFFFFF;
          .like{
            cursor: pointer;
            margin-right: 15px;
            &:hover{
              color: #FFC600;
            }
            &.selected{
              cursor: default;
              color: #FFC600;
            }
            &.blocked{
              cursor: default;
              color: #FFFFFF;
            }
          }
          .dislike{
            cursor: pointer;
            &:hover{
              color: #FFC600;
            }
            &.selected{
              cursor: default;
              color: #FFC600;
            }
            &.blocked{
              cursor: default;
              color: #FFFFFF;
            }
          }
        }
      }
      .rating{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        .title{
          font-size: 18px;
          line-height: 18px;
          font-weight: 500;
          color: #FFFFFF;
          margin-bottom: 10px;
        }
        label{
          margin-bottom: 0;
        }
        .MuiRating-root{
          .MuiRating-icon{
            color: #FFFFFF;
            &.MuiRating-iconHover,
            &.MuiRating-iconFocus,
            &.MuiRating-iconFilled{
              color: #FFC600;
            }
            .MuiSvgIcon-root{

            }
            :hover{
              .MuiSvgIcon-root{

              }
            }
          }
        }
        >.loader{
          flex-direction: row;
          display: flex;
          align-items: center;
          color: #556377;
          margin-left: 10px;
          svg{
            color: #faaf00;
          }
        }
      }
    }
    .evaluation_title{
      font-size: 15px;
      line-height: 15px;
      text-transform: uppercase;
      margin-bottom: 15px;
      text-align: center;
      color: ${props => props.win == null ? '#556377' : '#FFFFFF'};//#556377;
    }
    .loading{
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      svg{
        color: #FFC600;
      }
    }
  }

  .evaluation_question_tabs{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .question_tab{
      height: 2px;
      width: ${props => props.questions_total ? `calc(100% / ${props.questions_total} - 10px)` : "0"};
      background-color: #BFBFBF;
      &.active{
        background-color: #FFC600;
      }
      &.correct{
        background-color: green;
      }
      &.incorrect{
        background-color: red;
      }
    }
  }

  .evaluation_question_rest{
    text-align: center;
    margin-top: 20px;
  }

  .evaluation_question{
    font-size: 26px;
    line-height: 32px;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
  }



  .bt_next{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 44px;
    padding: 0 15px;
    background-color: #FFC600;
    border: 1px solid #FFC600;
    color: #000000;
    font-size: 17px;
    line-height: 17px;
    font-weight: 600;
    border-radius: 8px;
    margin-top: 65px;
    cursor: pointer;
    &.disabled{
      background-color: #E1E1E1;
      border: 1px solid #E1E1E1;
      color: #999999;
      cursor: default;
    }
    :hover{
      background-color: ${props => props.answer_selected ? '#FFFFFF' : '#E1E1E1'};
    }
  }
  .bt_close{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    right: 5px;
    top: 5px;
    width: 40px;
    height: 40px;
    border-radius: 15px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 0;
    border: 1px solid #FFC600;
    background-color: #FFC600;
    color: #000000;
    font-size: 26px;
    cursor: pointer;
    :hover{
      background-color: #FFFFFF;
      color: #FFC600;
    }
  }
  @media ${breakpoints.xs} {
    
    .evaluation_inner{
      width: 100%;
      padding: 20px 40px 20px 40px;
      
    }
  }
  @media ${breakpoints.sm} {
    width: auto;
    padding-top: 30px;
    .evaluation_inner{
      width: 720px;
      border-radius: 15px;
    }
  }
  @media ${breakpoints.md} {
    .evaluation_inner{
      width: 800px;
      padding: 40px 80px 40px 80px;
    }
  }
  @media ${breakpoints.lg} {

  }
`

const EvaluationContainer = (props) => {

  let { id_course, id_module } = useParams();

  const {_id,
         scope,
         title,
         handleClose,
         evaluationData,
         setEvaluationData,
         parentData,
         evaluation,
         setEvaluation,
         handleRefreshData } = props

  const { campus,
          setCampus,
          evaluationActive,
          setEvaluationActive,
          save_grade_like,
          take_user_evaluation,
          save_assesment } = useContext(ContentContext);

  const [ countOf, setCountOf ] = useState();
  const [ questionTotal, setQuestionTotal ] = useState();
  const [ order, setOrder ] = useState(0);
  const [ answersSelected, setAnswersSelected ] = useState([]);
  const [ answerEvaluation, setAnswerEvaluation ] = useState([]);
  const [ answerEvaluationObj, setAnswerEvaluationObj ] = useState({});
  const [ starEvaluationObj, setStarEvaluationObj ] = useState({});
  const [ likeEvaluationObj, setLikeEvaluationObj ] = useState({});
  const [ textButtonContinue, setTextButtonContinue ] = useState("Siguiente");
  const [ points, setPoints ] = useState(0);
  //const [ evaluation, setEvaluation ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ win, setWin ] = useState(null);
  const [ onResult, setOnResult ] = useState(null);
  const [ onPlay, setOnPlay ] = useState(null);
  const [ evaluationRating, setEvaluationRating ] = useState(0);
  const [ evaluationLike, setEvaluationLike ] = useState(null);
  const [ answerCorrect, setAnswerCorrect ] = useState([]);
  const [ answerIncorrect, setAnswerIncorrect ] = useState([]);
  const [ ladingRating, setLadingRating ] = useState(false);
  const [ ladingLike, setLadingLike ] = useState(false);
  
  const [ isRated, setIsRated ] = useState(false);
  const [ isLiked, setIsLiked ] = useState(false);

  useEffect(() => {

    setCountOf(1);
    setQuestionTotal(4);

    return () => {}
  }, [])

  useEffect(() => {

    setIsLoading(true);
    //getEvaluation();

    return () => {}
  }, [])

  const getEvaluation = () => {

    let response;
    if(evaluationData.type == "module"){
      response = take_user_evaluation(evaluationData.id, "");
      response.then((res) => {
        setIsLoading(false);
        setOnPlay(true);
        setEvaluation(path(['data', 'single'], res));
      })
    }
    if(evaluationData.type == "course"){
      response = take_user_evaluation("", evaluationData.id);
      response.then((res) => {
        setIsLoading(false);
        setOnPlay(true);
        setEvaluation(path(['data', 'single'], res));
      })
    }

  }

  const getEvaluationStatus = () => {

    let response;
    if(evaluationData.type == "module"){
      response = take_user_evaluation(evaluationData.id, "");
      response.then((res) => {
        setOnPlay(false);
        setOnResult(true);
        setEvaluation(path(['data', 'single'], res));
      })
    }
    if(evaluationData.type == "course"){
      response = take_user_evaluation("", evaluationData.id);
      response.then((res) => {
        setOnPlay(false);
        setOnResult(true);
        setEvaluation(path(['data', 'single'], res));
      })
    }

  }

  useEffect(() => {

    if(evaluation){
      setIsLoading(false);
      if(!onResult){
        setOnPlay(true);
      }
    }

    return () => {}
  }, [evaluation])



  useEffect(() => {
    console.log("---> evaluation", evaluation)
    if(evaluation){
      setQuestionTotal(evaluation.questions.length);
    }

    return () => {}
  }, [evaluation])

  useEffect(() => {

    return () => {
      setEvaluationData(null);
    }
  }, [])


  const handleNextQuestion = (index) => {
    //console.log("answersSelected", evaluation.questions[order]._id)


    if(answersSelected){
      let obj_response_question = {};
      let secondary_actions_arr = [];
      answersSelected.map((a, i) => {
        //console.log(evaluation.questions[order].secondary_actions, a)
        const response = getAnswedData(evaluation.questions[order].secondary_actions, a);
        //console.log("response", response)
        if(response){
          secondary_actions_arr.push(response);
        }
        //
      })

      obj_response_question[evaluation.questions[order]._id] = secondary_actions_arr;
      

      setAnswerEvaluation([...answerEvaluation, obj_response_question]);

      let obj = {...answerEvaluationObj};
      obj[evaluation.questions[order]._id] = secondary_actions_arr;
      setAnswerEvaluationObj(obj)
      




      const pointsGained = answerSuccess(evaluation.questions[order].score,
                                         evaluation.questions[order]?.penalty_score ? evaluation.questions[order]?.penalty_score : 0,
                                         evaluation.questions[order].secondary_actions,
                                         answersSelected);

      if(pointsGained){
        obj_response_question["status"] = pointsGained.status;
        obj_response_question["score"] = pointsGained.score;

        if(pointsGained.status === 1){
          setAnswerCorrect([...answerCorrect, index]);
        }else{
          setAnswerIncorrect([...answerIncorrect, index]);
        }
      }






    }else{

      return null;

    }
    
    const next = order + 1;
    evaluation.questions.length-1 == next && setTextButtonContinue("Enviar");

    if(evaluation.questions.length-1 >= next){
      setOrder(next);
      setAnswersSelected([]);
    }
    /*
    return null;

    if(answersSelected){

        let obj_response_question = {};
        let arr_option_id = [];
        let arr_option_text = [];
        let arr_option_media = [];

        answersSelected.map((a, i) => {
          const response = getAnswedData(evaluation.questions[order].secondary_actions, a);
          if(response){
            arr_option_id.push(response._id);
            arr_option_text.push(response.label);
            arr_option_media.push(response.media);
          }
        })

        obj_response_question["media"] = evaluation.questions[order].media;
        obj_response_question["optionSelectedId"] = arr_option_id;
        obj_response_question["questionText"] = evaluation.questions[order].title;
        obj_response_question["question_id"] = evaluation.questions[order]._id;

        if(evaluation.questions[order].input_type.title != 'single_choice_image' && evaluation.questions[order].input_type.title != 'multiple_choice_image'){
          obj_response_question["optionSelectedText"] = arr_option_text;
        }else{
          obj_response_question["optionSelectedText"] = [];
        }

        obj_response_question["status"] = '';
        obj_response_question["score"] = '';

        const pointsGained = answerSuccess(evaluation.questions[order].score,
                                           evaluation.questions[order]?.penalty_score ? evaluation.questions[order]?.penalty_score : 0,
                                           evaluation.questions[order].secondary_actions,
                                           answersSelected);

        if(pointsGained){

          obj_response_question["status"] = pointsGained.status;
          obj_response_question["score"] = pointsGained.score;//pointsGained.status === 1 ? evaluation.questions[order].score : 0;//evaluation.questions[order].score;//pointsGained.origina_score;

          if(pointsGained.status === 1){
            setAnswerCorrect([...answerCorrect, index]);
          }else{
            setAnswerIncorrect([...answerIncorrect, index]);
          }


          let newScore;
          if(pointsGained.action == "plus"){
            newScore = points + pointsGained.score;
          }
          if(pointsGained.action == "minus"){
            newScore = points - pointsGained.score;
          }
          setPoints(newScore);
        }

        setAnswerEvaluation([...answerEvaluation, obj_response_question]);

    }else{

      return null;

    }

    const next = order + 1;
    evaluation.questions.length-1 == next && setTextButtonContinue("Enviar");

    if(evaluation.questions.length-1 >= next){
      setOrder(next);
      setAnswersSelected([]);
    }
    */
  }
  

  const handleSendEvaluation = () => {

    const payload = { evaluation_id: evaluation._id,
                      //score: evaluation.score,
                      //approved: approved,
                      //id_content: scope == "module" ? id_module  : "",//id_module,
                      //id_curse: scope == "course" ? id_course  : "",//id_course,
                      //answers: answerEvaluationObj//JSON.stringify(answerEvaluationObj)//JSON.stringify(answerEvaluationObj)//answerEvaluation
                     }

    if(scope == "module"){
      payload.content_id = _id;
    }
    if(scope == "course"){
      const splited_course_id = _id.split(":");
      payload.course_id = splited_course_id[1];
    }



    // despues del response
    setOnPlay(false);
    setOnResult(true);




    





    // console.log("payload", payload);
    //return null;

    const response = save_assesment(payload, answerEvaluationObj);
    response.then(res => {
      let approved;
      if(res.data.single.isApproved){
        approved = true;
        setWin({status: true,
                back: 'rgba(0, 135, 85, 0.5)',
                media: icon_evaluation_win,//evaluation.modal_success.media,
                title: "¡Felicitaciones!",//evaluation.modal_success.title,
                description: "Has aprobado la evaluación."});//evaluation.modal_success.description});
                
      }else{
        approved = false;
        setWin({status: false,
                back: 'rgba(218, 41, 28, 0.5)',
                media: icon_evaluation_lose,//evaluation.modal_unsuccessfully.media,
                title: "Evaluación no aprobada",//evaluation.modal_unsuccessfully.title,
                description: "La evaluación no fue resuelta correctamente. ¡Vuelve a intearlo!"});//evaluation.modal_unsuccessfully.description});

      }

      setPoints(res.data.single.totalScore);







      if(scope === "course"){

        

          const course_splitted = _id.split(":");
          const campus_update = campus.categories.map(category => {
            if(course_splitted[0] === category._id){
              const repository_update = category.repository.map(repo => {
                if(repo._id === course_splitted[1]){
                  if(approved){
                    repo.status = STATUS_COURSE_COMPLETED;
                  }else{
                    repo.status = STATUS_COURSE_REPROVED;
                  }
                }
                return repo;
              })
            }
            return category;
          })
          console.log("campus", campus)
          const campus_update_arr = {...campus};
          campus_update_arr.categories = campus_update;
          setCampus(campus_update_arr);
  
          console.log("🦁 campus_update", campus_update_arr)

        
  
      }











      /*
      let approved;
      if(res.data.single.approved){

        if(scope == "module"){
          handleRefreshData();
        }

        approved = true;
        setWin({status: true,
                back: 'rgba(0, 85, 49, 0.5)',
                media: evaluation.modal_success.media,
                title: evaluation.modal_success.title,
                description: evaluation.modal_success.description});
      }else{
        approved = false;
        setWin({status: false,
                back: 'rgba(197, 0, 13, 0.5)',
                media: evaluation.modal_unsuccessfully.media,
                title: evaluation.modal_unsuccessfully.title,
                description: evaluation.modal_unsuccessfully.description});
      }

      getEvaluationStatus();
      */
    })
  }

  const handleEvaluationRating = (newValue) => {
    
    let payload = {};
    let objResponse = {};
    let obj_response_question = {};
    
    const survey_stars = evaluation.survey[0].questions.filter(question => question.type === "star");
    
    if(survey_stars.length){
      const option = survey_stars[0].secondary_actions.filter(option => option.label === newValue.toString());
      if(option.length){
        obj_response_question["optionSelectedId"] = [option[0]._id];
        obj_response_question["questionText"] = survey_stars[0].title;
        obj_response_question["_id"] = survey_stars[0]._id;
        obj_response_question["optionSelectedText"] = [option[0].label];
      }
      payload = { survey_id: survey_stars[0]._id, answers: [obj_response_question] }
      const splited_course_id = _id.split(":");
      payload.course_id = splited_course_id[1];

      setLadingRating(true);
      setIsRated(true);

      const grade_saved = save_grade_like(payload);
      grade_saved.then((res) => {
        setLadingRating(false);
      })

    }

    setEvaluationRating(newValue);
    return null;

  }

  const handleEvaluationLike = (newValue) => {
    
    let payload = {};
    let objResponse = {};
    let obj_response_question = {};
    
    const survey_like = evaluation.survey[0].questions.filter(question => question.type === "like");
    
    if(survey_like.length){
      const option = survey_like[0].secondary_actions.filter(option => option.label === newValue);
      if(option.length){
        obj_response_question["optionSelectedId"] = [option[0]._id];
        obj_response_question["questionText"] = survey_like[0].title;
        obj_response_question["_id"] = survey_like[0]._id;
        obj_response_question["optionSelectedText"] = [option[0].label];
      }
      payload = { survey_id: survey_like[0]._id, answers: [obj_response_question] }
      const splited_course_id = _id.split(":");
      payload.course_id = splited_course_id[1];

      setLadingLike(true);
      setIsLiked(true);

      const grade_saved = save_grade_like(payload);
      grade_saved.then((res) => {
        setLadingLike(false);
      })

    }

    setEvaluationLike(newValue);
    return null;
    
  }

  useEffect(() => {
    
    if(evaluation){
      if(answerEvaluation.length == evaluation.questions.length){
        handleSendEvaluation();
      }
    }

    return () => {}
  }, [answerEvaluation])



  return (
    <EvaluationStyled
      className={''}
      questions_total={questionTotal}
      answer_selected={answersSelected.length ? true : false}
      win={win}
    >

      <div className='evaluation_inner'>
        {isLoading ?
          <div className='loading'>
          <CircularProgress />
        </div> : ""}

          {evaluation ?
          <Fragment>
            <div className='bt_close' onClick={handleClose}>
              <IoCloseSharp />
            </div>
            <div className='evaluation_title'>
              {title}
            </div>

            <div className='evaluation_question_tabs'>
              {evaluation.questions && evaluation.questions.map((question, index) => {
                return(
                  <div className={`question_tab ${index == order ? 'active' : ''} ${answerCorrect.includes(index) ? ' correct' : ''} ${answerIncorrect.includes(index) ? ' incorrect' : ''}`}></div>
                )
              })}
            </div>
          </Fragment>
          : ""}

          {(!isLoading && onPlay && evaluation && !onResult) ?
          <Fragment>
            <div className='evaluation_question_rest'>
              <div className=''>Evaluación {order+1}/{questionTotal}</div>
            </div>
            <div className='evaluation_question'>
              {evaluation.questions[order].title}
            </div>
            {console.log("****", evaluation.questions[order])}
            {evaluation.questions[order].input_type[0].title == "single_choice" &&
              <MultipleChoice
                multiple={false}
                secondary_actions={evaluation.questions[order].secondary_actions}
                order={order}
                answersSelected={answersSelected}
                setAnswersSelected={setAnswersSelected}
                answerEvaluation={answerEvaluation}
                setAnswerEvaluation={setAnswerEvaluation}
                question={evaluation.questions[order]}
              />
            }

            {evaluation.questions[order].input_type[0].title == "multiple_choice" &&
              <MultipleChoice
                multiple={true}
                secondary_actions={evaluation.questions[order].secondary_actions}
                order={order}
                answersSelected={answersSelected}
                setAnswersSelected={setAnswersSelected}
                answerEvaluation={answerEvaluation}
                setAnswerEvaluation={setAnswerEvaluation}
                question={evaluation.questions[order]}
              />
            }

            {evaluation.questions[order].input_type[0].title == "single_choice_image" &&
              <MultipleChoiceWithImage
                multiple={false}
                secondary_actions={evaluation.questions[order].secondary_actions}
                order={order}
                answersSelected={answersSelected}
                setAnswersSelected={setAnswersSelected}
                answerEvaluation={answerEvaluation}
                setAnswerEvaluation={setAnswerEvaluation}
                question={evaluation.questions[order]}
              />
            }

            {evaluation.questions[order].input_type[0].title == "multiple_choice_image" &&
              <MultipleChoiceWithImage
                multiple={true}
                secondary_actions={evaluation.questions[order].secondary_actions}
                order={order}
                answersSelected={answersSelected}
                setAnswersSelected={setAnswersSelected}
                answerEvaluation={answerEvaluation}
                setAnswerEvaluation={setAnswerEvaluation}
                question={evaluation.questions[order]}
              />
            }
            <div className={`bt_next ${!answersSelected.length ? ' disabled' : ''}`} onClick={ () => { answersSelected.length && handleNextQuestion(order) } }>
              { textButtonContinue }
            </div>
          </Fragment> : "" }


        {!isLoading && onResult ?
          win ?
          <div className='box_result'>
            <div className={`media ${win.status ? 'imgWin' : 'imgLose'}`}>
              <img src={win.media} />
            </div>
            <div className='title'>{ win.title }</div>
            <div className='description'>{ win.status ? "Aprobaste la evaluación." : "La evaluación no fue resuelta correctamente. ¡Volvé a intearlo!"/*win.description*/ }</div>

            {win.status ?
            <>
              <div className='points'>Puntaje: { points > 0 ? points : '0'}</div>
              <div className='likes'>
                <div className='title'>¿Sentís que entendiste el contenido del curso?</div>
                <div className='like_actions'>
                  <div className={`like${evaluationLike === "Si" ? " selected" : evaluationLike !== null ? " blocked" : ""}`} onClick={() => !isLiked && handleEvaluationLike("Si")}>
                    <BiLike />
                  </div>
                  <div className={`dislike${evaluationLike === "No" ? " selected" : evaluationLike !== null ? " blocked" : ""}`} onClick={() => !isLiked && handleEvaluationLike("No")}>
                    <BiDislike />
                  </div>
                </div>
                {ladingLike ?
                <div className='loader'>
                  <CircularProgress size={20} />
                </div> : ""
                }
              </div>
              <div className='rating'>
                <div className='title'>¿Qué calificación le asignarías al curso?</div>
                <Rating
                  name="simple-controlled"
                  value={evaluationRating}
                  size="large"
                  readOnly={isRated}
                  onChange={(event, newValue) => {
                    handleEvaluationRating(newValue);
                  }}
                />

                {ladingRating ?
                <div className='loader'>
                  <CircularProgress size={20} />
                </div> : ""
                }
    
              </div> 
            </>
            : 
            "" }
          </div>
          :
          <div className='box_result_waiting'>
            <div className='loading'>
              <CircularProgress />
            </div>
          </div>
          : ""
        }
      </div>




    </EvaluationStyled>
  )
}

export default EvaluationContainer;
